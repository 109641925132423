.title {
  margin: 40px 0 30px 0;
  font-size: 30px;
}
.loginDiv {
  width: calc(100% - 40px);
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.mobileDiv {
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mobileCode {
  width: 100%;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
}
.sendButton {
  margin: 0 0 0 10px;
}
.loginBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.button {
  height: 44px;
  width: calc(100% - 96px);
  margin: 16px 48px 32px 48px;
  border-radius: 20px;
  border: none;
  background-color: #4CAF50;
}