.App {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
}
.title {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-family: "Microsoft Yahei", Arial, sans-serif;
  box-sizing: border-box;
}
.clock-icon {
  height: 14px;
  width: 14px;
  margin-right: 6px;
}
.sub-title {
  height: 13px;
  width: calc(100% - 32px);
  margin: 0px 16px;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  color: #00000099;
}
.section {
  width: calc(100% - 32px);
  height: 18px;
  line-height: 18px;
  margin: 6px 16px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-left: solid 8px #4c7255;
  font-size: 12px;
  font-weight: 900;
  box-sizing: border-box;
  padding-left: 6px;
}
.dashboard {
  height: 225px;
  width: calc(100% - 32px);
  background-color: #ff00000d;
  margin: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.data-div {
  height: 33%;
  width: 33%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.data-title {
  width: 100%;
  height: 14px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-money {
  width: 100%;
  height: 15px;
  font-size: 15px;
  font-weight: 700;
  color: #ff6969;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-compare {
  width: 143%;
  height: 10px;
  font-size: 10px;
  transform: scale(0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  color: #00000099;
}
.triangle-icon {
  height: 14px;
  width: 14px;
}
.data-percentdown {
  color: #008000;
}
.data-percentup {
  color: #f00;
}
.percentage {
  width: calc(100% - 32px);
  height: 100px;
  display: flex;
  justify-content: center;
  background-color: #ff00000d;
}
.percentage-layout {
  height: 100%;
  width: 25%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.percentage-offline {
  font-size: 15px;
  color: #ff6969;
}
.percentage-name {
  height: 18px;
  width: 100%;
  color: #00000080;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.red-point {
  content: " ";
  width: 14px;
  height: 14px;
  pointer-events: auto;
  border: none;
  border-radius: 200px;
  margin-right: 6px;
  background: rgb(255, 105, 105);
  filter: blur(2px);
}
.red-point2 {
  content: " ";
  width: 14px;
  height: 14px;
  pointer-events: auto;
  border: none;
  border-radius: 200px;
  margin-left: 6px;
  background: rgb(255, 105, 105);
  filter: blur(2px);
}
.top {
  width: calc(100% - 32px);
  background-color: #ff00000d;
  margin: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.top-title {
  width: 100%;
  height: 33px;
  background-color: #ff7878;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.top-ranking {
  width: 100%;
  height: 38px;
  color: #ffffff;
  background-color: #ff8c8c;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.top-sub-ranking {
  width: 100%;
  height: 38px;
  color: #ffffff;
  background-color: #ff8c8ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.order {
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background-color: #ff6464;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}
.top-name {
  height: 100%;
  width: 153px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12px;
  flex-grow: 1;
}
.top-money {
  height: 100%;
  width: 153px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.top-store-num {
  height: 100%;
  width: 100px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.user {
  width: calc(100% - 32px);
  height: 100px;
  margin: 8px 16px;
  background-color: #ff00000d;
  display: flex;
}
.age {
  width: calc(100% - 32px);
  height: 206px;
  margin: 0px 16px;
  background-color: #ff00000d;
  display: flex;
}
.blank {
  height: 100%;
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.user-money {
  height: 100px;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.user-percentage {
  height: 100%;
  width: calc(100%-210px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
